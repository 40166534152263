import React, { useEffect, useState } from "react"
import { ThemeProvider } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import CssBaseline from "@mui/material/CssBaseline"
import getTheme from "../theme"
import AOS from "aos"
import { graphql } from "gatsby"
import Main from "../layouts/Main"
import Sections from "../components/sections"
import SEO from "../components/seo"
import "../assets/css/github-markdown.css"

export const useDarkMode = () => {
  const [themeMode, setTheme] = useState("light")
  const [mountedComponent, setMountedComponent] = useState(false)

  const setMode = (mode) => {
    try {
      window.localStorage.setItem("themeMode", mode)
    } catch {
      /* do nothing */
    }

    setTheme(mode)
  }

  const themeToggler = () => {
    themeMode === "light" ? setMode("dark") : setMode("light")
  }

  useEffect(() => {
    try {
      const localTheme = window.localStorage.getItem("themeMode")
      localTheme ? setTheme(localTheme) : setMode("light")
    } catch {
      setMode("light")
    }

    setMountedComponent(true)
  }, [])

  return [themeMode, themeToggler, mountedComponent]
}

const DynamicPage = ({ data, pageContext }) => {

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side")
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }

    AOS.init({
      once: true,
      delay: 50,
      duration: 500,
      easing: "ease-in-out"
    })
  }, [])

  const [themeMode, themeToggler, mountedComponent] = useDarkMode()
  useEffect(() => {
    AOS.refresh()
  }, [mountedComponent, themeMode])

  const { contentSections, metadata, localizations } = data.strapiPage
  const global = data.strapiGlobal
  const articulos = data.allStrapiArticulos

  return (
    <>
      <SEO seo={metadata} global={global} />
      <ThemeProvider theme={getTheme(themeMode, themeToggler)}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Paper elevation={0}>
          <Main themeToggler={themeToggler} global={global}
                pageContext={{ ...pageContext, localizations }}>
            <Sections sections={contentSections} articulos={articulos} />
          </Main>
        </Paper>
      </ThemeProvider>
    </>
  )
}

export default DynamicPage

export const query = graphql`
    fragment GlobalData on StrapiGlobal {
        favicon {
            localFile {
                publicURL
            }
        }
        footer {
            id
            logo {
                dark {
                    url
                }
                bright {
                    url
                }
            }
            smallText
        }
        id
        metaTitleSuffix
        metadata {
            metaDescription
            metaTitle
            shareImage {
                localFile {
                    publicURL
                }
            }
        }
        navbar {
            id
            logo {
                dark {
                    url
                }
                bright {
                    url
                }
            }
        }
    }

    query DynamicPageQuery($id: String!, $locale: String!) {
        strapiGlobal(locale: { eq: $locale }) {
            ...GlobalData
        }
        strapiPage(id: { eq: $id }) {
            slug
            shortName
            metadata {
                metaTitle
                metaDescription
                shareImage {
                    localFile {
                        publicURL
                    }
                }
            }
            localizations {
                id
                locale
            }
            contentSections
        },
        allStrapiArticulos {
            nodes {
                cover {
                    url
                }
                autor {
                    nombre
                    foto {
                        url
                    }
                }
                id
                locale
                published
                subtitulo
                titulo
                contenido
                fecha
                slug
            }
        }
    }
`

